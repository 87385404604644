/*! ======== Paychex Kindex Main Stylesheet ================== */
@-ms-viewport {
  width: extend-to-zoom;
  zoom: 1.0;
}
/* ========================================================= */
/* ====================== BUTTONS ========================== */
/* ========================================================= */
button {
  /* Reset default button style */
  background: rgba(0, 0, 0, 0);
  border: 0;
  padding: 0;
  border-radius: 2px;
  border: outset 1px rgba(0, 0, 0, 0.2);
  /* Default Padding */
  padding: 12px;
  /* Default Height */
  height: 44px;
}
button.default-min-width {
  min-width: 80px;
}
button .png-btn-container {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  font-size: 0;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  pointer-events: none;
}
button .png-btn-container .png-btn-icon {
  pointer-events: none;
}
button .png-btn-container .png-btn-icon svg {
  pointer-events: none;
}
button .png-btn-container .png-btn-label {
  pointer-events: none;
}
button:not([disabled]) {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
}
button:hover:not([disabled]):not(.is-loading) {
  background-image: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1));
  box-shadow: 0px 2px 5px 0px rgba(155, 155, 155, 0.5);
}
button:active:not([disabled]):not(.is-loading) {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
  box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.1);
}
button:active > div {
  position: relative;
  top: 0px;
  left: 0px;
}
button:active:focus:not([disabled]):not(.is-loading) {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
  box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.1);
}
button:active:focus > div {
  position: relative;
  top: 0px;
  left: 0px;
}
button[disabled],
button:disabled {
  border: solid 1px #b7b7b7;
}
button[disabled] .png-btn-icon,
button:disabled .png-btn-icon {
  opacity: .2;
}
button.primary:not([disabled]) {
  background-color: #1b4585;
  color: #ffffff;
}
button.primary:not([disabled]) .png-btn-label {
  color: #ffffff;
}
button.secondary:not([disabled]) {
  background-color: #ffffff;
}
button.secondary:not([disabled]) .png-btn-label {
  color: #404040;
}
button.tertiary {
  background: none;
  border: none;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  /*
        &:active:focus {
            &:not([disabled]):not(.is-loading) {
                background-image: none;
                box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.1);
                border: none;
            }
        }
        */
}
button.tertiary:not([disabled]) .png-btn-label {
  color: #374953;
}
button.tertiary:not([disabled]):hover .png-btn-label {
  color: #0369b1;
}
button.tertiary:hover,
button.tertiary:active {
  background: none !important;
  border: none;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
}
button.large {
  padding: 15px;
  height: 50px;
}
button.small {
  /*
         * This fixes a strange bug where small, normal (no icon) buttons
         * shrink 2 pixels while in the message drawer and other places
         * but they look fine in the styleguide app and not otherwise in landing.
         *
         * If someone can find the style that is responsible for this height bug, we can remove this.
         *
         * -- blenzo 03/12/2015
         */
  height: 34px;
  padding: 7px;
}
button.small.is-normal {
  padding: 8px;
}
button.small .png-btn-label {
  line-height: 16px;
}
button.is-collapsed i.png-btn-icon,
button.is-collapsed md-icon.png-btn-icon {
  margin-left: 12px;
  margin-right: 12px;
}
button.is-expanded .png-btn-icon {
  margin-right: 8px;
}
button .png-btn-icon {
  margin-right: 12px;
  height: 18px;
  width: 16px;
}
button .png-btn-icon svg {
  pointer-events: none;
  display: block;
}
button .png-btn-label {
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;
  pointer-events: none;
}
button.primary .pngSpinner .pngSpinner-solid {
  fill: #ffffff;
}
button.primary .pngSpinner .pngSpinner-transparent {
  fill: rgba(255, 255, 255, 0.25);
}
button[disabled] .pngSpinner,
button:disabled .pngSpinner {
  display: none;
}
button:not([disabled]).is-loading .png-btn-container {
  position: relative;
}
button:not([disabled]).is-loading .png-btn-container > *:not(.pngSpinner) {
  visibility: hidden;
}
button:not([disabled]).is-loading .png-btn-container > .pngSpinner {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -8px;
  margin-top: -8px;
}
/* ========================================================= */
/* =====================    INPUT    ======================= */
/* ========================================================= */
input {
  border: none;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid #d8d8d8;
  font-family: Helvetica, Arial, sans-serif;
  color: #404040;
  font-size: 12px;
}
input:focus {
  border-bottom: 2px solid #0369b1;
}
input.error:focus {
  border-bottom: 2px solid #da3224;
}
input.error {
  border-bottom: 1px solid #da3224;
}
input.invalid {
  border: none;
}
input:disabled {
  border-bottom: 1px dotted #d8d8d8;
}
/* ========================================================= */
/* =================== GLOBAL STYLES ======================= */
/* ========================================================= */
html {
  height: 100%;
}
body {
  margin: 0px;
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  font-family: Helvetica, Arial, sans-serif;
}
header {
  color: #ffffff;
  background-color: #0369b1;
  height: 60px;
  -webkit-flex: none;
      -ms-flex: none;
          flex: none;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-left: 38px;
  padding-right: 38px;
}
header .paychex-logo {
  background-image: url("../images/logoWhite.svg");
  background-size: 200px 28px;
  background-repeat: no-repeat;
  width: 200px;
  height: 28px;
  cursor: pointer;
}
header .tagline {
  font-size: 16px;
}
footer {
  color: #898a8c;
  height: 140px;
  -webkit-flex: none;
      -ms-flex: none;
          flex: none;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
a {
  text-decoration: none;
  color: #004b8d;
  cursor: pointer;
}
a:hover {
  color: #0369b1;
  text-decoration: underline;
}
.main-content {
  -webkit-flex: 1 0 auto;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.view {
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.subheader {
  height: 120px;
  background-color: #ffffff;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
}
.subheader .navigation {
  font-size: 14px;
  cursor: pointer;
  vertical-align: middle;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 22px;
}
.subheader .navigation .back-indicator {
  background-image: url("../images/arrowBack.svg");
  background-size: 15px 15px;
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
  display: inline-block;
  padding-right: 10px;
  pointer-events: none;
}
.subheader .navigation .view-label {
  pointer-events: none;
}
.subheader .broker-label {
  font-size: 20px;
}
.input-text {
  font-family: Helvetica, Arial, sans-serif;
  color: #404040;
  font-size: 12px;
}
.link {
  color: #004b8d;
  cursor: pointer;
}
.link:hover {
  color: #0369b1;
  text-decoration: underline;
}
.mobile-indicator,
.tablet-indicator,
.desktop-indicator {
  position: absolute;
  left: -1000px;
  visibility: hidden;
}
.not-displayed {
  display: none !important;
}
@-webkit-keyframes pngSpinnerAnimation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes pngSpinnerAnimation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.loading-screen:not(.not-displayed) {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  background: rgba(1, 1, 1, 0.5);
  z-index: 1;
}
.loading-screen:not(.not-displayed) .loading-panel {
  max-width: 24px;
  height: 24px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 15px;
  margin-bottom: 15px;
}
.loading-screen:not(.not-displayed) .loading-panel .png-spinner {
  width: 100%;
  height: 100%;
  -webkit-animation: pngSpinnerAnimation .8s infinite linear;
          animation: pngSpinnerAnimation .8s infinite linear;
}
.loading-screen:not(.not-displayed) .loading-panel .png-spinner .solid {
  fill: #b7b7b7;
}
.loading-screen:not(.not-displayed) .loading-panel .png-spinner .transparent {
  fill: rgba(183, 183, 183, 0.25);
}
/*! ======== Desktop ================== */
@media only screen and (min-width: 1023px) {
  .mobile-indicator,
  .tablet-indicator {
    display: none;
  }
}
/*! ======== Tablet ================== */
@media only screen and (min-width: 700px) and (max-width: 1023px) {
  .mobile-indicator,
  .desktop-indicator {
    display: none;
  }
}
/*! ======== Mobile ================== */
@media only screen and (max-width: 700px) {
  .desktop-indicator,
  .tablet-indicator {
    display: none;
  }
  header .tagline {
    display: none;
  }
  footer {
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
header.landing {
  color: #0098ce;
  background-color: #ffffff;
  height: 100px;
}
header.landing .paychex-logo {
  background-image: url("../images/logoBlue.svg");
}
.landing-view nav {
  font-size: 14px;
  padding-left: 32px;
  padding-right: 38px;
  background-color: #eaeaea;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  height: 60px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.landing-view nav span {
  color: #404040;
  cursor: pointer;
  display: inline-block;
}
.landing-view nav span:first-child {
  padding-right: 35px;
}
.landing-view select {
  -webkit-appearance: none;
          appearance: none;
  background-image: url("../images/dropdownArrow.svg");
  background-size: 35px;
  background-repeat: no-repeat;
  background-position: right;
  background-color: white;
}
.landing-view select::-ms-expand {
  display: none;
}
.landing-view .main {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.landing-view .main .jumbotron {
  -webkit-flex: 1 100%;
      -ms-flex: 1 100%;
          flex: 1 100%;
  color: #f3f7fa;
  background-color: rgba(0, 152, 206, 0.5);
  background-image: url("../images/find-investment.jpg");
  background-size: cover;
  background-position: center;
  text-align: center;
  padding-bottom: 25px;
  height: 400px;
}
.landing-view .main .jumbotron h1 {
  margin-top: 110px;
  font-size: 54px;
  font-weight: lighter;
}
.landing-view .main .jumbotron h2 {
  margin-top: 35px;
  font-size: 24px;
}
.landing-view .main .jumbotron select {
  margin-top: 54px;
  font-size: 18px;
  padding: 15px;
  border-radius: 6px;
  width: 400px;
  border: 0px;
}
.landing-view .main .minitrons {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-flex: none;
      -ms-flex: none;
          flex: none;
  width: 1000px;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.landing-view .main .minitron {
  width: 300px;
  max-width: 300px;
  border-radius: 6px;
  color: #7d7d7d;
  border: 1px solid #d8d8d8;
  margin-top: 68px;
  background-size: cover;
  background-position: center;
}
.landing-view .main .minitron h2 {
  color: #ffffff;
  padding-top: 104px;
  padding-bottom: 36px;
  padding-left: 20px;
  font-size: 24px;
  font-weight: lighter;
  margin: 0;
  border-radius: 6px 6px 0px 0px;
}
.landing-view .main .minitron article {
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 32px;
  padding-bottom: 17px;
  font-size: 15px;
}
.landing-view .main .minitron select,
.landing-view .main .minitron .link-wrapper {
  height: 42px;
  background-color: #ffffff;
  font-size: 16px;
  margin-top: 22px;
  width: 100%;
  font-weight: lighter;
}
.landing-view .main .minitron .link-wrapper {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}
.landing-view .main .minitron .link-wrapper .link {
  pointer-events: none;
  display: inline-block;
  color: #0098ce;
}
.landing-view .main .minitron .link-wrapper .chevron {
  pointer-events: none;
  background-image: url("../images/chevronRight.svg");
  background-size: 35px;
  background-repeat: no-repeat;
  width: 35px;
  height: 35px;
}
.landing-view .main .minitron select {
  color: #0098ce;
  text-align: left;
  border: 0px;
}
.landing-view .main .minitron.about h2 {
  background-color: rgba(0, 152, 206, 0.5);
  background: -webkit-linear-gradient(rgba(0, 152, 206, 0.5), rgba(0, 152, 206, 0.5)), url("../images/about-us.jpg");
  background: linear-gradient(rgba(0, 152, 206, 0.5), rgba(0, 152, 206, 0.5)), url("../images/about-us.jpg");
  background-size: cover;
  background-position: center;
}
.landing-view .main .minitron.target-date h2 {
  background-color: rgba(3, 105, 177, 0.8);
  background: -webkit-linear-gradient(rgba(3, 105, 177, 0.8), rgba(3, 105, 177, 0.8)), url("../images/target-date.jpg");
  background: linear-gradient(rgba(3, 105, 177, 0.8), rgba(3, 105, 177, 0.8)), url("../images/target-date.jpg");
  background-size: cover;
  background-position: center;
}
.landing-view .main .minitron.model-portfolio h2 {
  background: -webkit-linear-gradient(rgba(124, 141, 153, 0.6), rgba(124, 141, 153, 0.6)), url("../images/model-portfolios.jpg");
  background: linear-gradient(rgba(124, 141, 153, 0.6), rgba(124, 141, 153, 0.6)), url("../images/model-portfolios.jpg");
  background-size: cover;
  background-position: center;
}
/*! ======== Desktop ================== */
@media only screen and (min-width: 1023px) {
  header.landing {
    color: #0098ce;
    background-color: #ffffff;
    height: 100px;
  }
  header.landing .paychex-logo {
    width: 300px;
    height: 40px;
    background-size: 286px 40px;
  }
}
/*! ======== Tablet ================== */
@media only screen and (min-width: 700px) and (max-width: 1023px) {
  .landing-view nav .main-pages span {
    padding-right: 30px;
  }
  .landing-view nav .secondary-pages span:first-child {
    padding-right: 30px;
  }
  .landing-view .main .jumbotron {
    background-image: url("../images/tablet/find-investment.jpg");
    background-size: cover;
    background-position: center;
  }
  .landing-view .main .jumbotron h1 {
    margin-top: 80px;
    font-size: 48px;
  }
  .landing-view .main .jumbotron h2 {
    font-size: 22px;
  }
  .landing-view .main .minitrons {
    -webkit-flex: none;
        -ms-flex: none;
            flex: none;
    width: 680px;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}
/*! ======== Mobile ================== */
@media only screen and (max-width: 700px) {
  header.landing {
    height: 68px;
  }
  .landing-view nav {
    height: 40px;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
  .landing-view nav .main-pages {
    display: none;
  }
  .landing-view nav .secondary-pages {
    white-space: nowrap;
  }
  .landing-view nav .secondary-pages span:first-child {
    padding-right: 35px;
  }
  .landing-view .main .jumbotron {
    background-image: url("../images/mobile/find-investment.jpg");
    background-size: cover;
    background-position: center;
    height: 350px;
  }
  .landing-view .main .jumbotron h1 {
    margin-top: 40px;
    font-size: 40px;
  }
  .landing-view .main .jumbotron h2 {
    margin-top: 40px;
    font-size: 20px;
  }
  .landing-view .main .jumbotron select {
    margin-top: 20px;
    font-size: 18px;
    padding: 10px;
    width: 270px;
  }
  .landing-view .main .minitrons {
    -webkit-flex: none;
        -ms-flex: none;
            flex: none;
    max-width: 300px;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .landing-view .main .minitrons .minitron {
    margin-top: 20px;
  }
}
/* ========================================================= */
/* ===================== INVESTMENTS ======================= */
/* ========================================================= */
.investments-view {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #f3f7fa;
}
.investments-view .tertiary-header {
  height: 50px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
}
.investments-view .investment-panel-wrapper {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
}
.investments-view .filter-bubble-panel {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
}
.investments-view .funds-panel {
  display: inline-block;
  max-width: 1550px;
  -webkit-flex: 1 100%;
      -ms-flex: 1 100%;
          flex: 1 100%;
  border-radius: 2px;
  border: 1px solid #aaacae;
  background-color: #ffffff;
}
.investments-view .funds-panel .funds-panel-header {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
}
.investments-view .funds-panel .funds-panel-header .matching-funds {
  color: #404040;
  margin-right: 15px;
  font-size: 24px;
}
.investments-view .funds-panel .filter-bubble-panel {
  min-height: 30px;
  padding-left: 20px;
  padding-right: 20px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
.investments-view .funds-panel .filter-bubble-panel .filter-bubble {
  background-color: #0369b1;
  color: #ffffff;
  border-radius: 10px;
  height: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 12px;
  cursor: pointer;
  text-transform: uppercase;
  margin-right: 15px;
  margin-top: 15px;
  white-space: nowrap;
}
.investments-view .funds-panel .filter-bubble-panel a {
  color: #0369b1;
  white-space: nowrap;
  margin-top: 15px;
}
.investments-view .funds-panel .filter-bubble-panel .filter-bubble:after {
  content: '\0000a0\0000a0\0000a0\0000a0X';
}
.investments-view .funds-panel .fund-list {
  margin-top: 15px;
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
}
.investments-view .funds-panel .fund-list tr {
  color: #404040;
  background-color: #ffffff;
  border-bottom: 1px solid #d8d8d8;
  border-top: 1px solid #d8d8d8;
  font-size: 14px;
}
.investments-view .funds-panel .fund-list tr.empty-row {
  border-bottom: 0px;
}
.investments-view .funds-panel .fund-list th {
  text-align: left;
  vertical-align: middle;
  cursor: pointer;
  font-size: 13px;
  color: #898a8c;
}
.investments-view .funds-panel .fund-list td,
.investments-view .funds-panel .fund-list th {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;
}
.investments-view .funds-panel .fund-list td {
  text-align: left;
  vertical-align: top;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.investments-view .funds-panel .fund-list th.th-vi-k {
  /* Ticker column */
  width: 65px;
}
.investments-view .funds-panel .fund-list th.th-vm-s {
  /* Model Id column */
  width: 35px;
}
.investments-view .funds-panel .fund-list th.th-vm-o {
  /* Model portfolio asset category column */
  width: 130px;
}
.investments-view .funds-panel .fund-list th.chevron {
  width: 20px;
}
.investments-view .funds-panel .fund-list td.chevron {
  background-image: url("../images/chevronRight.svg");
  background-size: 35px 35px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}
.investments-view .funds-panel .fund-list th.first-col,
.investments-view .funds-panel .fund-list td.first-col {
  padding-left: 15px;
}
.investments-view .funds-panel .fund-list th.last-col,
.investments-view .funds-panel .fund-list td.last-col {
  padding-right: 15px;
}
.investments-view .funds-panel .fund-list th.th-vi-s {
  /* Share Class column */
  width: 60px;
}
.investments-view .funds-panel .fund-list th.th-vi-a {
  /* Advisor Compensation column */
  width: 75px;
}
.investments-view .funds-panel .fund-list th.th-vi-r {
  /* Restrictions column */
  white-space: nowrap;
}
.investments-view .funds-panel .fund-list th .sort-indicator-ascending,
.investments-view .funds-panel .fund-list th .sort-indicator-descending {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-size: 15px 15px;
  background-position: center bottom;
  background-repeat: no-repeat;
  pointer-events: none;
}
.investments-view .funds-panel .fund-list th .sort-indicator-ascending {
  background-image: url("../images/arrowUp.svg");
}
.investments-view .funds-panel .fund-list th .sort-indicator-descending {
  background-image: url("../images/arrowDown.svg");
}
.investments-view .funds-panel .fund-list-footer {
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 15px;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
}
.investments-view .funds-panel .fund-list-footer .fund-list-pager {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.investments-view .funds-panel .fund-list-footer .fund-list-pager .prev {
  background: #ffffff url("../images/chevronLeft.svg") center no-repeat;
}
.investments-view .funds-panel .fund-list-footer .fund-list-pager .next {
  background: #ffffff url("../images/chevronRight.svg") center no-repeat;
}
.investments-view .funds-panel .fund-list-footer .fund-list-pager .prev,
.investments-view .funds-panel .fund-list-footer .fund-list-pager .next {
  display: inline-block;
  background-size: 35px 35px;
  width: 25px;
  height: 50px;
  cursor: pointer;
}
.investments-view .filter-panel-wrapper {
  display: inline-block;
  min-width: 240px;
  margin-right: 30px;
  padding: 0;
}
.investments-view .filter-panel-wrapper .filter-panel {
  padding-top: 10px;
  font-size: 12px;
  border-radius: 2px;
  border: 1px solid #aaacae;
  background-color: #ffffff;
}
.investments-view .filter-panel-wrapper .filter-panel .filter-subsection {
  margin-left: 10px;
  margin-right: 10px;
}
.investments-view .filter-panel-wrapper .filter-panel .filter-item-wrapper {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 10px;
}
.investments-view .filter-panel-wrapper .filter-panel .filter-item-wrapper input {
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  -webkit-flex-grow: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
}
.investments-view .filter-panel-wrapper .filter-panel .filter-item-wrapper input[type="radio"] {
  width: 18px;
  height: 18px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-top: 0px;
  margin-right: 20px;
}
.investments-view .filter-panel-wrapper .filter-panel .filter-item-wrapper label {
  color: #000000;
}
.investments-view .filter-panel-wrapper .filter-panel h1,
.investments-view .filter-panel-wrapper .filter-panel section {
  margin-top: 0px;
  margin-bottom: 26px;
  margin-left: 10px;
  margin-right: 10px;
}
.investments-view .filter-panel-wrapper .filter-panel h1 h2,
.investments-view .filter-panel-wrapper .filter-panel section h2,
.investments-view .filter-panel-wrapper .filter-panel h1 h3,
.investments-view .filter-panel-wrapper .filter-panel section h3 {
  padding-bottom: 10px;
  font-family: Helvetica, Arial, sans-serif;
  color: #9b9b9b;
  font-size: 14px;
  margin: 0px;
  font-weight: normal;
}
.investments-view .filter-panel-wrapper .filter-panel h1 h3,
.investments-view .filter-panel-wrapper .filter-panel section h3 {
  padding-bottom: 1px;
  padding-top: 1px;
}
.investments-view .filter-panel-wrapper .filter-panel h1 .checkboxes-filter,
.investments-view .filter-panel-wrapper .filter-panel section .checkboxes-filter {
  height: 200px;
  overflow: auto;
}
.investments-view .filter-panel-wrapper .filter-panel h1 .checkboxes-filter label,
.investments-view .filter-panel-wrapper .filter-panel section .checkboxes-filter label {
  padding-left: 20px;
}
.investments-view .filter-panel-wrapper .filter-panel h1 .checkboxes-filter div,
.investments-view .filter-panel-wrapper .filter-panel section .checkboxes-filter div {
  padding-bottom: 10px;
}
.investments-view .filter-panel-wrapper .filter-panel h1 select,
.investments-view .filter-panel-wrapper .filter-panel section select {
  width: 180px;
  padding: 2px;
}
.investments-view .filter-panel-wrapper .filter-panel h1 {
  color: #404040;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 18px;
}
.investments-view .filter-panel-wrapper .filter-panel hr {
  margin-top: 8px;
  margin-bottom: 7px;
  width: 100%;
  border-top: 1px solid #9b9b9b;
}
.investments-view .filter-panel-wrapper .filter-panel .targetDateSetFilter {
  height: 300px;
  overflow: auto;
}
.investments-view .filter-panel-wrapper .filter-panel section#advisor-compensation-filter-section input {
  width: 40px;
}
.investments-view .filter-panel-wrapper .filter-panel section#advisor-compensation-filter-section span {
  color: #9b9b9b;
  margin-left: 10px;
  margin-right: 10px;
}
.investments-view .filter-panel-wrapper .filter-panel section#advisor-compensation-filter-section .advisor-compensation-error-message {
  display: none;
  color: #da3224;
  font-size: 12px;
}
.investments-view .filter-panel-wrapper .filter-panel section.buttons {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.investments-view .filter-panel-wrapper .filter-panel section.buttons button {
  width: 47%;
  -webkit-flex: none;
      -ms-flex: none;
          flex: none;
}
.investments-view .detail-overlay {
  display: none;
  position: fixed;
  width: 100%;
  left: 0px;
  top: 0px;
  height: 100%;
  background: rgba(1, 1, 1, 0.5);
  overflow: auto;
}
.investments-view .detail-overlay .detail-wrapper {
  margin-top: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 500px;
  background-color: #f3f7fa;
  padding-bottom: 15px;
}
.investments-view .detail-overlay .detail-wrapper .detail-header {
  border-radius: 2px;
  border: 1px solid #aaacae;
  background-color: #ffffff;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  min-width: 200px;
  font-size: 24px;
  padding: 15px;
}
.investments-view .detail-overlay .detail-wrapper .detail-header .detail-close {
  font-weight: bold;
  cursor: pointer;
}
.investments-view .detail-overlay .detail-wrapper .detail-panel {
  border-radius: 2px;
  border: 1px solid #aaacae;
  background-color: #ffffff;
  margin: 15px;
}
.investments-view .detail-overlay .detail-wrapper .detail-panel td {
  padding: 15px;
}
.investments-view .detail-overlay .detail-wrapper .detail-panel .detail-row-title {
  color: #898a8c;
  width: 35px;
}
.investments-view .detail-overlay .detail-wrapper .detail-footer {
  margin-right: 15px;
  margin-left: 15px;
}
.detail-matc-link,
.matc-link {
  display: none;
}
#fund-search {
  background: #ffffff url("../images/search.svg") left no-repeat;
  padding-left: 24px;
  padding-bottom: 5px;
}
/*! ======== Desktop ================== */
@media only screen and (min-width: 1023px) {
  .investments-view .tertiary-header div {
    display: none;
  }
  .investments-view th.chevron,
  .investments-view td.chevron {
    display: none;
  }
  .model-view .funds-panel .fund-list-footer {
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .model-view .detail-matc-link,
  .model-view .matc-link {
    display: block;
  }
}
/*! ======== Tablet ================== */
@media only screen and (min-width: 700px) and (max-width: 1023px) {
  .investments-view .subheader .download button {
    display: none;
  }
  .investments-view .filter-panel-wrapper {
    display: none;
  }
  .investments-view .filter-panel-wrapper.show-on-mobile {
    display: block;
    position: fixed;
    width: 100%;
    left: 0px;
    top: 0px;
    height: 100%;
    background: rgba(1, 1, 1, 0.5);
    overflow: auto;
  }
  .investments-view .filter-panel-wrapper.show-on-mobile .filter-panel {
    margin: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 500px;
  }
  .investments-view th.chevron,
  .investments-view td.chevron {
    display: none;
  }
  .model-view .funds-panel .fund-list-footer {
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .model-view .detail-matc-link,
  .model-view .matc-link {
    display: block;
  }
}
/*! ======== Mobile ================== */
@media only screen and (max-width: 700px) {
  .investments-view .investment-panel-wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
  .investments-view .subheader .download button {
    display: none;
  }
  .investments-view .filter-panel-wrapper {
    display: none;
  }
  .investments-view .filter-panel-wrapper.show-on-mobile {
    display: block;
    position: fixed;
    width: 100%;
    left: 0px;
    top: 0px;
    height: 100%;
    background: rgba(1, 1, 1, 0.5);
    overflow: auto;
  }
  .investments-view .filter-panel-wrapper.show-on-mobile .filter-panel {
    margin: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 500px;
  }
  .investments-view .fund-list tr td:nth-child(4),
  .investments-view .fund-list tr th:nth-child(4),
  .investments-view .fund-list tr td:nth-child(5),
  .investments-view .fund-list tr th:nth-child(5),
  .investments-view .fund-list tr td:nth-child(6),
  .investments-view .fund-list tr th:nth-child(6),
  .investments-view .fund-list tr td:nth-child(7),
  .investments-view .fund-list tr th:nth-child(7),
  .investments-view .fund-list tr td:nth-child(8),
  .investments-view .fund-list tr th:nth-child(8) {
    display: none;
  }
  .investments-view .detail-overlay.show-on-mobile {
    display: block;
  }
  .model-view .detail-matc-link {
    display: block;
  }
}
/* ========================================================= */
/* ===================== about ======================= */
/* ========================================================= */
.about-view {
  display: inline-block;
  background-color: #f3f7fa;
}
.about-panel-wrapper {
  display: block;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #aaacae;
  background-color: #ffffff;
  font-family: Helvetica, Arial, sans-serif;
  color: #404040;
  font-size: 16px;
}
/* ========================================================= */
/* ===================== contact ======================= */
/* ========================================================= */
.contact-view {
  display: inline-block;
  background-color: #f3f7fa;
}
.contact-label-all {
  font-family: Helvetica, Arial, sans-serif;
  color: #404040;
  font-size: 12px;
  color: #9b9b9b;
  height: 12px;
  padding-bottom: 2px;
  padding-top: 0px;
  margin-top: 0px;
  margin-bottom: 10px;
}
#contactUsConfirmation {
  display: none;
  font-size: 16px;
}
.contact-label {
  font-family: Helvetica, Arial, sans-serif;
  color: #404040;
  font-size: 12px;
  color: #9b9b9b;
  height: 12px;
  padding-bottom: 2px;
  padding-top: 0px;
  margin-top: 0px;
  margin-bottom: 10px;
  position: absolute;
  top: 0;
}
input:focus + label {
  color: #0369b1;
  font-size: 12px;
}
.thanks {
  color: #404040;
  font-size: 20px;
}
.contact-input {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 12px;
  max-width: 250px;
  width: 100%;
  height: 14px;
  margin-top: 0px;
  margin-bottom: 5px;
  padding-bottom: 10px;
  padding-top: 0px;
  padding-left: 0px;
  position: absolute;
  bottom: 0;
  color: #404040;
}
.contact-panel-wrapper {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #aaacae;
  background-color: #ffffff;
}
.contact-panel-wrapper .contact-form {
  width: 100%;
}
.contact-panel-wrapper .contact-input-group {
  position: relative;
  height: 56px;
  margin-bottom: 15px;
}
.contact-panel-wrapper .contact-comments {
  max-width: 500px;
  width: 100%;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 12px;
  height: 100px;
  overflow: hidden;
  resize: none;
  margin-bottom: 20px;
  margin-top: 10px;
  color: #404040;
  border-radius: 2px;
  border: 1px solid #aaacae;
}
/* ========================================================= */
/* ===================== contact ======================= */
/* ========================================================= */
.error-view {
  display: inline-block;
  background-color: #f3f7fa;
}
.error-panel-wrapper {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #aaacae;
  background-color: #ffffff;
}
.error-heading {
  color: #da3224;
  font-size: 16px;
  padding-bottom: 20px;
}
.error-message {
  color: #404040;
  font-size: 14px;
}
